// src/Profile.js
import React from 'react';
import './Profile.css';

const Profile = () => {
  const name = "Elyasa";
  const title = "AI Developer";
  const bio = "Passionate AI developer with a focus on machine learning and natural language processing. Committed to building intelligent solutions.";
  
  const skills = ["Python", "TensorFlow", "PyTorch", "JavaScript", "React", "Natural Language Processing"];
  const projects = [
    {
      name: "Chatbot",
      description: "An AI-powered chatbot built with NLP techniques.",
      link: "https://github.com/elyasa/chatbot"
    },
    {
      name: "Image Classifier",
      description: "A deep learning model for classifying images.",
      link: "https://github.com/elyasa/image-classifier"
    }
  ];

  return (
    <div className="profile-container">
      <h1>{name}</h1>
      <h2>{title}</h2>
      <p>{bio}</p>
      
      <h3>Skills</h3>
      <ul>
        {skills.map(skill => <li key={skill}>{skill}</li>)}
      </ul>

      <h3>Projects</h3>
      <ul>
        {projects.map(project => (
          <li key={project.name}>
            <a href={project.link} target="_blank" rel="noopener noreferrer">{project.name}</a> - {project.description}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Profile;
