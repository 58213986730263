
import Profile from '../Profile';


const About = () => {
  return <Profile />;
};

export default About;







